import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  InputGroup,
  Button,
  Modal,
  Alert,
  Form,
} from 'react-bootstrap';
import {
  shippingEstimate,
  postalCodeToAddress,
} from '../../services/pharmaceuticalCare';
import MaskedFormControl from 'react-bootstrap-maskedinput';
export default function FreteButton({
  productAvailable,
  setFinalAddress,
  setFrete,
}) {
  const addressInitialState = {
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: '',
    postalCode: '',
  };
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState(addressInitialState);
  const [shipping, setShipping] = useState([]);
  const [freteChoosed, setFreteChoosed] = useState({});

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  useEffect(() => {
    checkAddressNumberError();
  }, [address, postalCode, address.number]);

  useEffect(() => {
    shippingCalculate(query.get('cep'));
  }, []);

  const checkAddressNumberError = () => {
    if (address['number'] === '') setError('Informe o número do endereço');
    else setError('');
  };

  const shippingCalculate = async (postalCode) => {
    setLoading(true);
    setAddress(addressInitialState);
    setError('');
    const address = await postalCodeToAddress(postalCode);
    if (address && address.type !== 'service_error') {
      setAddress({ ...address, postalCode: postalCode });
    } else {
      setLoading(false);
      return false;
    }

    const shipping = await shippingEstimate(postalCode);
    if (shipping.length === 0) {
      setError('CEP não atendido pela MEVO');
      setLoading(false);
      return false;
    }

    const enablePurchaseShipping = shipping.filter((s) => s.EnablePurchase);

    setShipping(enablePurchaseShipping);
    setLoading(false);
    checkAddressNumberError();
  };

  const updateAddress = (fieldName, fieldValue) => {
    setAddress({ ...address, [fieldName]: fieldValue });
  };

  const chooseFrete = (frete) => {
    setFreteChoosed(frete);
    setFrete(frete);
    setFinalAddress(address);
    setShow(false);
  };

  const shippingUnavailable = {
    ShippingMethod: '2d',
    DeliveryTimeInMinutes: 2880,
    EstimatedDeliveryTime: '2 dias úteis',
    ShippingPrice: 0,
    ShippingPriceLabel: 'Entrega Grátis',
  };

  const validateCep = (cep) => {
    return /^[0-9]{5}-[0-9]{3}$/.test(cep);
  };
  return (
    <>
      <Button onClick={() => setShow(true)}>Calcular Frete</Button>
      <Modal center size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>Calcular Frete</Modal.Header>
        <Modal.Body>
          <Col>
            <InputGroup className="mb-3">
              <MaskedFormControl
                disabled={loading}
                placeholder="Digite o CEP"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={address.postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                  updateAddress('postalCode', e.target.value);
                  validateCep(e.target.value) &&
                    shippingCalculate(e.target.value);
                }}
                mask="11111-111"
              />
            </InputGroup>
          </Col>
          <Form>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Estado"
                  value={address.state}
                  onChange={(e) => updateAddress('state', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Cidade"
                  value={address.city}
                  onChange={(e) => updateAddress('city', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Bairro"
                  value={address.neighborhood}
                  onChange={(e) =>
                    updateAddress('neighborhood', e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Rua"
                  value={address.street}
                  onChange={(e) => updateAddress('street', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Número"
                  value={address.number}
                  onChange={(e) => updateAddress('number', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="estado">
              <Col>
                <Form.Control
                  disabled={loading}
                  type="text"
                  placeholder="Complemento"
                  value={address.complement}
                  onChange={(e) => updateAddress('complement', e.target.value)}
                />
              </Col>
            </Form.Group>
          </Form>
          <Col>
            {error !== '' && <Alert variant="danger">{error}</Alert>}
            {error === '' && !productAvailable && shipping.length > 0 && (
              <Row>
                <Col sm={1}>
                  <Form.Check
                    onChange={(e) =>
                      e.target.checked && chooseFrete(shippingUnavailable)
                    }
                    inline
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                  />
                </Col>
                <Col sm={2}>
                  <p>{shippingUnavailable.ShippingMethod}</p>
                </Col>
                <Col sm={7}>
                  <p>{shippingUnavailable.EstimatedDeliveryTime}</p>
                </Col>
                <Col sm={2}>
                  <p>{shippingUnavailable.ShippingPriceLabel}</p>
                </Col>
              </Row>
            )}
            {error === '' &&
              productAvailable &&
              shipping.map((item) => {
                return (
                  <Row>
                    <Col sm={1}>
                      <Form.Check
                        inline
                        name="group1"
                        onChange={(e) => e.target.checked && chooseFrete(item)}
                        type="radio"
                        id={`inline-radio-1`}
                        checked={freteChoosed === item}
                      />
                    </Col>
                    <Col sm={2}>
                      <p>{item.ShippingMethod}</p>
                    </Col>
                    <Col sm={7}>
                      <p>{item.EstimatedDeliveryTime}</p>
                    </Col>
                    <Col sm={2}>
                      <p>{item.ShippingPriceLabel}</p>
                    </Col>
                  </Row>
                );
              })}
          </Col>
          {freteChoosed && (
            <div className="rightAlign" variant="warning">
              <Button onClick={() => setShow(false)}>Fechar</Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
