import React, { useEffect } from 'react'
import { GoogleLogin, useGoogleLogin } from 'react-google-login'
import { AuthService } from '../../services/AuthServices'
const clientId = process.env.REACT_APP_CLIENT_ID

export function AuthenticationDialog(props) {
  const onSuccessGoogleAuthentication = (response) => {
    AuthService.signIn(response)
    if (typeof props?.onAuthenticate !== 'function') return
    props?.onAuthenticate(response)
  }

  const onFailGoogleAuthentication = () => {
    AuthService.signOut()
    if (typeof props?.onAuthenticateFail !== 'function') return
    props?.onAuthenticateFail()
  }

  const { loaded } = useGoogleLogin({ clientId })

  useEffect(() => {
    if (loaded) props?.onLoad?.()
  }, [loaded, props])

  return (
    <GoogleLogin
      loginHint={'teste'}
      clientId={clientId}
      buttonText={'Autenticar'}
      onSuccess={onSuccessGoogleAuthentication}
      onFailure={onFailGoogleAuthentication}
      cookiePolicy={'single_host_origin'}
    />
  )
}
