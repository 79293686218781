const orderStauses = {
  all: '',
  order_canceled: {
    title: 'Pedido Cancelado',
    variant: 'danger',
    reasonText: true,
    externalOrderReference: false,
  },
  order_completed: {
    title: 'Pedido Concluído',
    variant: 'success',
    reasonText: false,
    externalOrderReference: false,
  },
  order_ready_to_delivery: {
    title: 'Pedido pronto para entrega',
    variant: 'warning',
    reasonText: false,
    externalOrderReference: true,
  },
  order_placed: { title: 'Pedido Feito', variant: 'primary' },
};

export default orderStauses;
