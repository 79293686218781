import React from 'react';
import EstoqueVirtualVTEX from '../../components/EstoqueVirtualVTEX';
import { getEVOrders } from '../../services/warehouse';

class EstoqueVirtualVTEXContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lista: [],
            loading: false
        }
        this.getOrders =  this.getOrders.bind(this);
    }

    async getOrders(){
        this.setState({loading: true});
        let resultados = []
        try {
            resultados = await getEVOrders();
            resultados = resultados.sort(function (a, b) {
                console.log("A1: ", a);
                console.log("B1: ", b);
                a = a.data_pedido_cliente.substring(0, 11).split('/').reverse().join('');
                b = b.data_pedido_cliente.substring(0, 11).split('/').reverse().join('');
                console.log("A: ", a);
                console.log("B: ", b);
                return a > b ? 1 : a < b ? -1 : 0;
              }).reverse();
        } catch (err) {
            alert("Erro ao buscar pedidos." +  JSON.stringify(err))
        }
        this.setState({lista: resultados, loading: false});
    }

    async componentDidMount(){
        await this.getOrders();
    }   

    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <div>
                            carregando...
                        </div>
                        :
                        <EstoqueVirtualVTEX
                            lista={this.state.lista}
                            loading={this.state.loading}
                            refresh={this.getOrders}
                        />
                }
            </div>            
        );
    }
}

export default EstoqueVirtualVTEXContainer;