import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { searchProducts } from '../../services/pharmaceuticalCare';
export default function BuscarMedicamentosModal({ addProduct }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);

  const searchProductsByTerm = async (searchTerm) => {
    const products = await searchProducts(searchTerm);
    setProducts(products);
  };

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Adicionar mais medicamentos
      </Button>
      <Modal
        fullscreen={true}
        show={show}
        size="lg"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pesquisar Medicamentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Digite Medicamentos"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                e.target.value.length > 2 &&
                  searchProductsByTerm(e.target.value);
              }}
            />
          </InputGroup>
          {products.map((p) => {
            return (
              <Row>
                <Col xs={6}>{p.Label}</Col>
                <Col>{p.AvailableItens}</Col>
                <Col>{formatter.format(p.Price)}</Col>
                <Col>
                  <Button
                    onClick={() => {
                      addProduct(p);
                      handleClose();
                    }}
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
