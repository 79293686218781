import React from 'react';
import {Container, Row, Col, FormControl} from 'react-bootstrap';
import Title from '../../components/Title';
import './styles.css';


class EstoqueVirtualVTEX extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtro: null,
            filterText: null
        }
        this.filterResults = this.filterResults.bind(this);
        this.getResult = this.getResult.bind(this);
    }
    async componentDidMount() {

    }

    getQuantidadeAtendida(pedido, ean){
        let quantidadeAtendida = 0;
        pedido.retornos.map((retorno) => {
            retorno.itens_pedido.map((item) => {
                if(item.ean === ean){
                    quantidadeAtendida += item.quantidade_atendida;
                }
                return null;
            })
            return null;
        });
        return quantidadeAtendida;
    }

    filterResults(e){
        this.setState({filterText: e.target.value});
    }

    getResult(){
        if(this.state.filterText !== null && this.state.filterText !== ''){
            return  this.props.lista.filter(e => 
                JSON.stringify(e).toUpperCase().includes(this.state.filterText.toUpperCase()))
        }
        return this.props.lista;
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'Estoque Virtual VTEX'} />
                    </Col>
                </Row>   
                <Row>
                    <FormControl 
                        placeholder='digite o que deseja buscar'
                        onChange={this.filterResults}
                    />
                </Row>
                <Row>
                    {
                        this.getResult(this.props.lista).map(x => (
                            <div className='card'>
                                <div className='card-header'>
                                    <span>
                                        <strong>id:</strong> {x.id}
                                    </span>
                                    <span>
                                        <strong>id_vtex:</strong> {x.referencia_externa}
                                    </span>
                                    <span>
                                        <strong>status:</strong> {x.status}
                                    </span>
                                </div>
                                <div className='card-body'>
                                    <div className='card-body-line'>
                                        <div>
                                            <span>Data do pedido:  </span>
                                            <span>{x.data_pedido_cliente}</span>
                                        </div>
                                        <div>
                                            <span>Distribuidoras Solicitadas:  </span>
                                            <span>{x.arquivo_envios.length}</span>
                                        </div>
                                    </div>
                                    <div className='card-body-line'>
                                        <div>
                                            <span>Modalidade de Entrega:  </span>
                                            <span>{x.modalidade_entrega}</span>
                                        </div>
                                        <div>
                                            <span>Entrega Estimada em:  </span>
                                            <span>{x.data_estimada_entrega}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span>Itens do pedido (EV): </span>
                                        {
                                            x.itens_consolidados.map((item) => (
                                                <div className='card-item'>
                                                    <div>
                                                        <span>ean: </span>
                                                        <span>{item.ean}</span>
                                                    </div>
                                                    <div>
                                                        <span>pedido: </span>
                                                        <span>{item.pedido}</span>
                                                    </div>
                                                    <div>
                                                        <span>retornado: </span>
                                                        <span>{item.retornado}</span>
                                                    </div>
                                                    <div>
                                                        <span>faturado: </span>
                                                        <span>{item.faturado}</span>
                                                    </div>
                                                    <div>
                                                        <span>recebido: </span>
                                                        <span>{item.recebido}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='card-footer'>
                                        {
                                            x.temProblema === true ? 
                                            <div style={{'color':'red'}}>
                                                <strong>status compra:</strong> Com Problema
                                            </div>
                                            :
                                            <div style={{'color':'green'}}>
                                                <strong>status compra:</strong> Tudo certo
                                            </div>
                                        }
                                        <div>
                                            {x.motivoProblema}
                                        </div>
                                        <div>
                                            <strong>Status VTEX: </strong> {x.vtex_status}
                                    </div>
                                    <div>
                                        <strong>Status separacao: </strong> {x.separacao}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Row>
            </Container>
        );
    }
}

export default EstoqueVirtualVTEX;