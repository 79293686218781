import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../components/Title';
import './styles.css';
import FunilContainer from '../../containers/Funil/FunilContainer';


class Funil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    async componentDidMount() {

    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'SETUP DE FUNIL'} />
                    </Col>
                </Row>
                <Row>
                    <FunilContainer />
                </Row>
            </Container>
        );
    }
}

export default Funil;