import { AuthService } from './AuthServices';
import {myAxios} from './axios';

export const getUsers = async () => {
    const response = await myAxios.get('/user/');
    return response.data;
}

export const getUser = async (userEmail) => {
  const response = await myAxios.get(`/user/${userEmail}`);
  return response.data;
}

export const getAuthenticatedUser = async () => {
  const loginUser = await AuthService.getCurrentAuthenticatedUser();
  const userEmail = loginUser?.email
  if (!userEmail) return
  const user = await getUser(userEmail);
  
  localStorage.setItem('nexoInfo', JSON.stringify(user));
  return user
}

export const createUser = async (user) => {
    const response = await myAxios.post('/user/', user);
    return response.data;
} 

export const editUser = async (userId, user) => {
    const response = await  myAxios.put(`/user/${userId}`, user);
    return response.data;
}
