import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Alert,
  Form,
} from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { completeOrder as completeOrderService } from '../../services/pharmaceuticalCare';
import { sendCallFeedback } from '../../services/pharmaceuticalCare';

export default function FinalizarCompra({
  generalInfo,
  productsCart,
  address,
  frete,
  totalPrice,
}) {
  const [show, setShow] = useState(false);
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [email, setEmail] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [PaymentType, setPaymentType] = useState('card');
  const [OrderStatus, setOrderStatus] = useState('pending');

  const [errorCpf, setErrorCpf] = useState(true);
  const [errorRg, setErrorRg] = useState(true);
  const [errorBirthDate, setErrorBirthDate] = useState(true);

  useEffect(() => {
    if (validateCpf(cpf)) setErrorCpf(false);
    else setErrorCpf(true);
    if (validateRg(rg)) setErrorRg(false);
    else setErrorRg(true);
    if (validateBirthDate(birthDate)) setErrorBirthDate(false);
    else setErrorBirthDate(true);
  }, [cpf, rg, birthDate, errorCpf, errorRg, errorBirthDate]);

  const [toggleAddress, setToggleAddress] = useState(false);
  const [toggleFrete, setToggleFrete] = useState(false);

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const validateCpf = (parameterCpf) => {
    return !!parameterCpf.match(/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/);
  };

  const validateRg = (parameterRg) => {
    return !!parameterRg.match(/\d{4}/);
  };

  const validateBirthDate = (parameterBirthDate) => {
    return !!parameterBirthDate.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/);
  };

  const formatItems = () => {
    return Object.keys(productsCart).map((k) => {
      delete productsCart[k]['AvailableItens'];
      productsCart[k]['UnitPrice'] = productsCart[k]['Price'];
      productsCart[k]['TotalPrice'] =
        productsCart[k]['Price'] * productsCart[k]['Quantity'];
      return productsCart[k];
    });
  };

  const formatShipping = () => {
    return {
      BuyerEmail: email,
      BuyerMobilePhone: mobilePhone,
      BuyerName: generalInfo.PatientName,
      BuyerCPF: cpf,
      BuyerRG: rg,
      BuyerBirthDate: birthDate,
      AddressType: 'Rua',
      ZipCode: address.cep,
      Address: address.street,
      AddressNumber: address.number,
      AddressComplement: address.complement,
      AddressDistrict: address.neighborhood,
      AddressCity: address.city,
      AddressState: address.state,
    };
  };

  const completeOrder = async (callId) => {
    setOrderStatus('sending');
    const order = {
      OrderItems: formatItems(),
      OrderShipping: formatShipping(),
      OperationStatus: 'order_placed',
      TotalValue: totalPrice,
      TotalDiscount: 0,
      DiscountPercentage: 0,
      DiscountCoupom: 0,
      PaymentType: PaymentType,
      ShippingType: frete.ShippingMethod,
      ShippingPrice: frete.ShippingPrice,
    };
    const orderResponse = await completeOrderService(order, callId);
    if (orderResponse === 200) {
      setOrderStatus('completed');
    } else setOrderStatus('error');
  };

  const refuseOrder = async (callId) => {
    await sendCallFeedback(callId, 'order_canceled');
    setOrderStatus('canceled');
  };

  if (OrderStatus === 'canceled') {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="warning">Pedido cancelado com sucesso!</Alert>
          </Col>
        </Row>
      </Container>
    );
  }
  if (OrderStatus === 'completed') {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="success">Pedido realizado com sucesso!</Alert>
          </Col>
        </Row>
      </Container>
    );
  }
  if (OrderStatus === 'error') {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">
              Pedido não realizado, entre em contato para mais detalhes!
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Button onClick={() => setShow(true)} variant="success">
        FinalizarCompra
      </Button>

      <Modal center size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <h4>Resumo do Pedido</h4>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              <h5>Paciente</h5>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  Nome
                </Form.Label>
                <Col sm="8">
                  <p style={{ paddingTop: 8 }}>{generalInfo.PatientName}</p>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  CPF*
                </Form.Label>
                <Col sm="8">
                  <MaskedFormControl
                    isValid={false}
                    plaintext
                    placeholder="000.000.000-00"
                    mask="111.111.111-11"
                    onChange={(e) => setCpf(e.target.value)}
                    className="finalizar"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  Email*
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    isValid={false}
                    plaintext
                    type="email"
                    placeholder="email@dominio.com"
                    onChange={(e) => setEmail(e.target.value)}
                    className="finalizar"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  Telefone Celular*
                </Form.Label>
                <Col sm="8">
                  <MaskedFormControl
                    isValid={false}
                    plaintext
                    mask="(11) 11111-1111"
                    onChange={(e) => setMobilePhone(e.target.value)}
                    className="finalizar"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  RG
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    onChange={(e) => setRg(e.target.value)}
                    plaintext
                    placeholder="999999999"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="finalizar"
              >
                <Form.Label column sm="4">
                  Data de Nascimento
                </Form.Label>
                <Col sm="8">
                  <MaskedFormControl
                    plaintext
                    placeholder="08/08/1986"
                    mask="11/11/1111"
                    onChange={(e) => setBirthDate(e.target.value)}
                    className="finalizar"
                  />
                </Col>
              </Form.Group>
              {errorCpf && (
                <Row style={{ paddingTop: 10 }}>
                  <Col>
                    <Alert variant="danger">
                      Preenchimento do CPF Obrigatório
                    </Alert>
                  </Col>
                </Row>
              )}
              {email.length < 5 && (
                <Row style={{ paddingTop: 10 }}>
                  <Col>
                    <Alert variant="danger">
                      Preenchimento do email Obrigatório
                    </Alert>
                  </Col>
                </Row>
              )}
              {mobilePhone.length < 10 && (
                <Row style={{ paddingTop: 10 }}>
                  <Col>
                    <Alert variant="danger">
                      Preenchimento do celular Obrigatório
                    </Alert>
                  </Col>
                </Row>
              )}
              <hr />
              <h5>Produtos</h5>
              <Row>
                <Col xs={8}>
                  <b>Nome</b>
                </Col>
                <Col xs={2}>
                  <b>Quantidade</b>
                </Col>
                <Col xs={2}>
                  <b>Preço</b>
                </Col>
              </Row>
              {Object.keys(productsCart).map((key) => {
                return (
                  <Row>
                    <Col xs={8}>{productsCart[key].Label}</Col>
                    <Col xs={2}>{productsCart[key].Quantity}</Col>
                    <Col xs={2}>
                      {formatter.format(productsCart[key].Price)}
                    </Col>
                  </Row>
                );
              })}
              <hr />
              <h5
                onClick={() => setToggleAddress(!toggleAddress)}
                style={{ cursor: 'pointer' }}
              >
                Endereço
              </h5>
              {toggleAddress && (
                <div>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      Rua
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.street}</p>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      Bairro
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.neighborhood}</p>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      Estado
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.state}</p>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      CEP
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.cep}</p>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      Número
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.number}</p>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    className="finalizar"
                  >
                    <Form.Label column sm="4">
                      Complemento
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{address.complement}</p>
                    </Col>
                  </Form.Group>
                </div>
              )}
              <hr />
              <h5
                onClick={() => setToggleFrete(!toggleFrete)}
                style={{ cursor: 'pointer' }}
              >
                Frete
              </h5>
              {toggleFrete && (
                <div>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="4">
                      Prazo de Entrega
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>
                        {frete.EstimatedDeliveryTime}
                      </p>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="4">
                      Preço Frete
                    </Form.Label>
                    <Col sm="8">
                      <p style={{ marginTop: 8 }}>{frete.ShippingPriceLabel}</p>
                    </Col>
                  </Form.Group>
                </div>
              )}
              <hr />
              <h5>Pagamento</h5>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="4">
                  Preço Total
                </Form.Label>
                <Col sm="8">
                  <p style={{ marginTop: 8 }}>
                    <b>{formatter.format(frete.ShippingPrice + totalPrice)}</b>
                  </p>
                </Col>
              </Form.Group>
              <Row>
                <Col sm={1}>
                  <Form.Check
                    inline
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={PaymentType === 'card'}
                    onChange={(e) => e.target.checked && setPaymentType('card')}
                  />
                </Col>
                <Col sm={5}>
                  <p>Cartão</p>
                </Col>
                <Col sm={1}>
                  <Form.Check
                    inline
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={PaymentType === 'pix'}
                    onChange={(e) => e.target.checked && setPaymentType('pix')}
                  />
                </Col>
                <Col sm={5}>
                  <p>Pix</p>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={OrderStatus === 'sending'}
            variant="warning"
            onClick={async () => await refuseOrder(generalInfo.CallId)}
          >
            Desistir do pedido
          </Button>
          <Button
            disabled={
              errorCpf ||
              email.length < 5 ||
              mobilePhone.length < 10 ||
              OrderStatus === 'sending'
            }
            variant="success"
            onClick={async () => await completeOrder(generalInfo.CallId)}
          >
            Finalizar Pedido
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
