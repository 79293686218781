import { enviromentVariables } from '../vars';
import axios from 'axios';
import { AuthService } from './AuthServices';

const defaultOptions = {
  baseURL: enviromentVariables.backofficeApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
};

let myAxios = axios.create(defaultOptions);

myAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwt');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

myAxios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    AuthService.signOut();
  }
  return Promise.reject(error);
});

export { myAxios }
