import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../../components/Title';
import './styles.css';
import LigacaoAtencaoFarmaceutica from '../../components/LigacaoAtencaoFarmaceutica';
import { getNextCall } from '../../services/pharmaceuticalCare';
import GetNextCallButton from '../../components/GetNextCallButton';
import BotaoCarrinhoWhatsapp from '../../components/BotaoCarrinhoWhatsapp';
import PrescriptionGetCartModal from '../../components/PrescriptionGetCartModal';
import { useHistory } from "react-router-dom";



function Atendimento() {
  const [nextCall, setNextCall] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const nextCallPOS = async () => {
    setNextCall({});
    const nextCall = await getNextCall();
    if (nextCall === 'null') {
    } else setNextCall(nextCall);
  };

  const openPrescriptionCodeModal = () => {
    setOpenModal(!openModal);
  };

  const DirectToCart = (callId, cep='04558-005') => {
    openPrescriptionCodeModal();
    const path = '/venda-farmacia/' + callId + '?cep=' + cep;
    window.location.href = path; 
  };

  return (
    <Container>
      <Row>
        <Col>
          <Title title={'Fila de Comunicação'} />
        </Col>
      </Row>
      <Row>
        {nextCall && nextCall.CallId && (
          <Col>
            <LigacaoAtencaoFarmaceutica
              call={nextCall}
              getNextCall={nextCallPOS}
            />
          </Col>
        )}
        {!nextCall && (
          <Col>
            <BotaoCarrinhoWhatsapp callback={openPrescriptionCodeModal} />
          </Col>
        )}
        {!nextCall && (
          <Col>
            <GetNextCallButton getNextCall={nextCallPOS} />
          </Col>
        )}
      </Row>
      <Row>
        <PrescriptionGetCartModal 
          open={openModal}
          handleClose={openPrescriptionCodeModal}
          callback={DirectToCart}
        />
      </Row>
    </Container>
  );
}

export default Atendimento;