import React from 'react';
import {Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import { getAuthenticatedUser } from '../../services/userService';
import { searchPrescriptionForPDV } from '../../services/prescriptionService';
import { uploadDocumentoITI } from '../../services/documentService';
import './styles.css'


class PDV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            prescription: null,
            resultsFor: '',
            loading: false,
            selectedFile: null
        }
        this.handleSearch =  this.handleSearch.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.fileInputRef = React.createRef()
    }

    async handleFileChange(event) {
        this.setState({
            selectedFile: event.target.files[0]
        });
    }
    
    async handleUpload() {
        if (!this.state.selectedFile) {
            alert('Por favor, selecione um arquivo.');
            return;
        }
        try {
            const response = await uploadDocumentoITI(this.state.prescription.IdPrescricao,this.state.selectedFile);
            this.setState(prevState => ({
                prescription: {
                  ...prevState.prescription,
                  PDFUrlValidacaoITI: response
                }
              }));
        } catch (error) {
          console.error('Error occurred during file upload:', error);
        }
    }

    handlePageClick(page){
        window.location.href=`/${page}`;
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value}, () => {
            const buttonEnabled = !!this.state.codigo || !!this.state.email || !!this.state.celular || !!this.state.nome || !!this.state.idPaciente;
            this.setState({buttonEnabled});
        });
    }

    async componentDidMount() {
        const user = await getAuthenticatedUser();
        this.setState({availableFeatures: user.features})
    }

    async handleSearch(){
        this.setState({loading: TextTrackCueList})
        const payload = {
            "orderId": this.state.orderId
        }
        const prescription = await searchPrescriptionForPDV(payload);
        this.setState({prescription, orderId: '', resultsFor: this.state.orderId, loading: false});
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'Conferência de receita de pedidos:'} />
                    </Col>
                </Row>
                <Row>
                    <Col md='5' sm='12' xs='5'>
                            <div className='form-container'>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.orderId}
                                        placeholder='Código do pedido'
                                        onChange = {(e) => this.handleInput(e,'orderId')}
                                    />   
                                </InputGroup>
                            </div>
                    </Col>
                    <Col md='auto' sm='auto' xs='auto'>
                            <Button
                                className="btn"
                                variant="info"
                                block
                                onClick={this.handleSearch}
                                disabled={!this.state.orderId}
                                label={'BUSCAR'}>
                                    BUSCAR
                            </Button>
                    </Col>
                </Row>    
                {
                        this.state.loading ? 
                        <Row>
                            <Loading/>
                        </Row>
                        :
                        null
                }    
                {
                this.state.prescription === null ?
                <h4> Nenhuma receita listada </h4> :
                <Row style={{paddingTop: '40px'}}>
                    <Col md='auto' sm='auto' xs='auto'>
                                    <div>
                                        <h4> Mostrando resultados para: {this.state.resultsFor} </h4>
                                        <div className='prescricao-container'>
                                            <span>DATA PRESCRIÇÃO: <strong>{this.state.prescription.DataCriacao}</strong></span>
                                            <span>CÓDIGO DA RECEITA: <strong>{this.state.prescription.CodigoValidacao}</strong></span>
                                            {
                                                this.state.prescription.PrescricaoAssinada ?
                                                <span className='card-receita-assinada'>RECEITA ASSINADA</span>:
                                                <span className='card-receita-n-assinada'>RECEITA NÃO ASSINADA</span>
                                            } 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Paciente </h3>
                                                </div>    
                                                <div>
                                                    <span>Nome: {this.state.prescription.Paciente.Nome}</span>                                                
                                                </div>     
                                                <div>
                                                    <span>Idade: {this.state.prescription.Paciente.Idade}</span>
                                                </div>
                                                <div>
                                                    <span>Telefone: {this.state.prescription.TelefoneCelular}</span>
                                                </div>           
                                                <div>
                                                    <span>Sexo: {this.state.prescription.Paciente.Sexo}</span>
                                                </div> 
                                                <div>
                                                    <span>Endereço: {this.state.prescription.Paciente.Endereco}</span>
                                                </div> 
                                            </div> 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Prescritor </h3>
                                                </div>    
                                                <div>
                                                    <span>Nome: {this.state.prescription.Prescritor.Nome}</span>                                                
                                                </div>     
                                                <div>
                                                    <span>CPF: {this.state.prescription.Prescritor.Documento}</span>
                                                </div>    
                                                <div>
                                                    <span>{this.state.prescription.Prescritor.Conselho}:  {this.state.prescription.Prescritor.Numero}-{this.state.prescription.Prescritor.UF} </span>
                                                </div> 
                                                <div>
                                                    <span>Contato Estabelecimeto: {this.state.prescription.Estabelecimento.Contato.TelefoneComercial}</span>
                                                </div> 
                                            </div> 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Itens Estruturados </h3>
                                                </div>    
                                                {
                                                    this.state.prescription.Itens.map((item) => (
                                                        <div className='prescricao-item-container'>
                                                            <div><strong> {item.Label} - Qtd: {item.Quantidade} </strong> </div>
                                                            <div>{item.Posologia}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>                     
                                        </div>
                                    </div>
                                
                    </Col>   
                    <Col md='auto' sm='auto' xs='auto'>
                        <div className='documentos-container'>
                            <div>
                                Documentos Associados a Dispensa
                            </div>
                            <div className='documentos-item'>
                                <div className='documentos-title'> 
                                    Receita Médica
                                </div>
                                <div>
                                    <Button onClick={()=> window.open(this.state.prescription.PDFUrl, "_blank")}>
                                                    Baixar
                                    </Button>
                                </div>
                            </div>
                            <div className='documentos-item'>
                                <div className='documentos-title'> 
                                    Validacao ITI
                                </div>
                                <div>
                                    {
                                        this.state.prescription.PDFUrlValidacaoITI ?
                                        <Button onClick={()=> window.open(this.state.prescription.PDFUrlValidacaoITI, "_blank")}>
                                            Baixar
                                        </Button>
                                        :
                                        <div>
                                            <div style={{margin: "5px"}}>
                                                <input type="file" onChange={this.handleFileChange} />
                                            </div>
                                            <div style={{margin: "5px"}}>
                                                <Button variant="success" onClick={this.handleUpload} disabled={!this.state.selectedFile}>
                                                    Realizar Upload
                                                </Button>
                                            </div>
                                        </div>
                                    }                            
                                </div>
                            </div>
                            <div className='documentos-item'>
                                <div className='documentos-title'> 
                                    Formulario de Dispensação
                                </div>
                                <div>
                                    <Button disabled={!this.state.prescription.PDFUrlFormularioDispensacao} onClick={()=> window.open(this.state.prescription.PDFUrlFormularioDispensacao, "_blank")}>
                                                    Baixar
                                    </Button>
                                </div>
                            </div>
                            <div className='documentos-item'>
                                <div className='documentos-title'> 
                                    Documento consolidado
                                </div>
                                <div>
                                    <Button disabled={!this.state.prescription.PDFUrlDocumentoConsolidado} onClick={()=> window.open(this.state.prescription.PDFUrlDocumentoConsolidado, "_blank")}>
                                                    Baixar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col> 
                </Row>  
                }
            </Container>
        );
    }
}

export default PDV;