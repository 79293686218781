import React from 'react';
import {Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import { getAuthenticatedUser } from '../../services/userService';
import { searchForPrescriptionOrder } from '../../services/prescriptionOrder';
import './styles.css'


class FormularioEntrega extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            prescription: null,
            resultsFor: '',
            loading: false
        }
        this.handleSearch =  this.handleSearch.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handlePageClick(page){
        window.location.href=`/${page}`;
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value});
    }

    async componentDidMount() {
        const user = await getAuthenticatedUser();
        this.setState({availableFeatures: user.features})
    }

    async handleSearch(){
        this.setState({loading: true})
        const payload = {
            "orderId": this.state.orderId
        }
        const documentUrl = await searchForPrescriptionOrder(payload.orderId);
        window.open(documentUrl, "_blank")
        this.setState({orderId: '', resultsFor: this.state.orderId, loading: false});
    }


    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'Conferência de formulario de entrega (Portaria344):'} />
                    </Col>
                </Row>
                <Row>
                    <Col md='5' sm='12' xs='5'>
                            <div className='form-container'>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.orderId}
                                        placeholder='Código do pedido'
                                        onChange = {(e) => this.handleInput(e,'orderId')}
                                    />   
                                </InputGroup>
                            </div>
                    </Col>
                    <Col md='auto' sm='auto' xs='auto'>
                            <Button
                                className="btn"
                                variant="info"
                                block
                                onClick={this.handleSearch}
                                disabled={!this.state.orderId}
                                label={'BUSCAR'}>
                                    BUSCAR
                            </Button>
                    </Col>
                </Row>    
                {
                        this.state.loading ? 
                        <Row>
                            <Loading/>
                        </Row>
                        :
                        null
                }    
            </Container>
        );
    }
}

export default FormularioEntrega;