import React, { useState } from 'react';
import { Flex } from '../../App';
import SvgSearchFail from '../../assets/svg/SearchFail';
import { getSubscriptions } from '../../services/subscription';
import {
  FilterBox,
  statusToSearch,
  validateEmail,
  LineSearch,
} from './components';
import './styles.css';

function SearchBox({ onSearch, disabled = () => false, ...props }) {
  const [value, setValue] = useState();

  return (
    <Flex>
      <input
        className="my-input"
        value={value}
        onChange={(key) => setValue(key.target.value)}
        {...{ ...props }}
      />
      <button
        className="my-button"
        onClick={() => onSearch(value)}
        disabled={!disabled(value)}
      >
        buscar
      </button>
    </Flex>
  );
}

function Farmacia({ history }) {
  const [searchStatusSelected, setSearchStatusSelected] = useState();
  const [subs, setSubs] = useState(null);

  async function onSearch(customerEmail) {
    setSearchStatusSelected(null);
    const subscriptions = await getSubscriptions({ customerEmail });
    setSubs(subscriptions);
  }

  const onClickSubscription = async (e) => {
    if (!e?.id) return;
    window.location.replace('/dispensacao/' + e.id);
  };

  return (
    <div>
      <SearchBox
        disabled={validateEmail}
        placeholder="Insira o email do assinante"
        {...{ onSearch }}
      />
      <FilterBox
        {...{ statusToSearch, setSearchStatusSelected, searchStatusSelected }}
      />
      {subs?.length || subs === null ? (
        subs?.map(
          (sub) =>
            (!searchStatusSelected || searchStatusSelected === sub?.status) && (
              <LineSearch onClick={() => onClickSubscription(sub)} sub={sub} />
            )
        )
      ) : (
        <Flex style={{ paddingTop: 150, justifyContent: 'center' }}>
          <SvgSearchFail />
        </Flex>
      )}
    </div>
  );
}

export default Farmacia;
