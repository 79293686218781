import { getAuthenticatedUser } from "./userService";

export const AuthService = {
  async signIn(userInfo) {
    localStorage.setItem('jwt', userInfo?.tokenId);
    localStorage.setItem('userInfo', JSON.stringify(userInfo.profileObj));
    await getAuthenticatedUser();
    window.location.reload();
  },
  signOut() {
    window.sessionStorage.removeItem("access_token");
    window.sessionStorage.removeItem("nama");
    localStorage.removeItem('jwt');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('nexoInfo');
    window.location.replace('/');
  },
  isSignIn() {
    const jwt = localStorage.getItem('jwt');
    const userInfo = localStorage.getItem('userInfo');
    const nexoInfo = localStorage.getItem('nexoInfo');
    return !!jwt && !!userInfo && !!nexoInfo
  },
  async getCurrentAuthenticatedUser(){
    try {
      const userInfo = localStorage.getItem('userInfo');
      return JSON.parse(userInfo);
    } catch (e) {
      return null;
    }
  },
};
