import {myAxios} from './axios'

export const getSubscriptions = async ({ customerEmail, status, id }) => {
    const response = await myAxios.get(`subscription/${id || ''}`, { params: { customerEmail, status } })
    return response.data
}

export const postSubscription = async (id, body) => {
    const response = await myAxios.post(`subscription/${id || ''}`, body)
    return response.data
}

export const getOrder = async order => {
    const response = await myAxios.get('subscription/order/' + order)
    return response.data
}

export const getCheckoutInfo = async customerEmail => {
    const response = await myAxios.get('subscription/checkout', { params: { customerEmail }})
    return response.data
}
