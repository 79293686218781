import React from 'react';
import './styles.css';

class Title extends React.Component {
    render() {
      return (
        <div className='title-container'>
            <h5>
                {this.props.title}
            </h5>
        </div>);
    }
  }

export default Title;