import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { getCheckoutInfo, getSubscriptions, postSubscription } from '../../services/subscription'
import { Flex } from '../../App'

export function DetalhesFarmacia() {
    const { id } = useParams()

    const [sub, setSub] = useState({})
    const [subInfo, setSubInfo] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        const subscriptions = await getSubscriptions({ id })
        setSub(subscriptions)
        const checkoutInfo = await getCheckoutInfo(subscriptions?.customerEmail)
        setSubInfo(checkoutInfo)
        setLoading(false)
    }, [])

    if (loading) return <div>carregando</div>

    const { userProfile } = subInfo
    const { shippingAddress, purchaseSettings } = sub
    const { status, createdAt, nextPurchaseDate, items } = sub
    const subscriptionDate = new Date(createdAt)?.toLocaleDateString('pt-BR')
    const paymentId = purchaseSettings?.paymentMethod?.paymentAccountId
    const addressId = shippingAddress?.addressId
    const price = items?.reduce((acum, item) => acum + (item?.priceAtSubscriptionDate * item?.quantity), 0)
    const address = subInfo?.availableAddresses?.find(e => e.addressId === addressId)
    const payment = subInfo?.availableAccounts?.find(e => e.accountId === paymentId)
    const activeOrPaused = ['PAUSED', 'ACTIVE'].includes(status)
    const showPauseButton = status === 'ACTIVE'
    const showUnpauseButton = status === 'PAUSED'
    const showJumpButton = activeOrPaused && !sub?.isSkipped
    const showUnjumpButton = activeOrPaused && sub?.isSkipped === true

    const changeSubscription = async ({ status, isSkipped }) => {
        await postSubscription(id, { status, isSkipped })
        window.location.reload();
    }

    const onClickGoBack = () => window.location.replace('/dispensacao/')

    return (
        <div>
            <h2>Detalhes da assinatura</h2>
            <Flex style={{ justifyContent: 'space-between' }}>
                {showPauseButton && <button onClick={() => changeSubscription({ status: 'PAUSED' })}>Pausar</button>}
                {showUnpauseButton && <button onClick={() => changeSubscription({ status: 'ACTIVE' })}>Despausar</button>}
                {showJumpButton && <button onClick={() => changeSubscription({ isSkipped: true })}>Pular</button>}
                {showUnjumpButton && <button onClick={() => changeSubscription({ isSkipped: false })}>Despular</button>}
                {activeOrPaused && <button onClick={() => changeSubscription({ status: 'CANCELED' })}>Cancelar</button>}
            </Flex>
            <div>Status da assinatura: {status}</div>
            <div>Contratação: {subscriptionDate}</div>
            <div>Próxima entrega: {nextPurchaseDate}</div>
            <div>Valor da compra: {price}</div>
            <div>Email do cliente: {sub?.customerEmail}</div>
            <div>Nome do cliente: {userProfile?.firstName} {userProfile?.lastName}</div>
            <div>Forma de pagamento: {payment?.paymentSystemName}</div>
            <div>Número do cartão: {payment?.cardNumber}</div>
            <div>Endereço: {address?.street}</div>
            <div>Número: {address?.number}</div>
            <div>Bairro: {address?.neighborhood}</div>
            <div>Complemento: {address?.complement}</div>
            <div>Cidade: {address?.city}</div>
            <div>UF: {address?.state}</div>
            <button onClick={onClickGoBack}>voltar</button>
        </div>
    )
}

export default DetalhesFarmacia
