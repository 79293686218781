import React from 'react';
import {Row, Col, FormControl, InputGroup, Button, Form, Modal} from 'react-bootstrap';
import { createCallForDirectCart } from '../../services/pharmaceuticalCare';


class PrescriptionGetCartModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prescriptionCode: ""
        }
        this.handleInput =  this.handleInput.bind(this);
        this.handleCreateCart = this.handleCreateCart.bind(this);
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value});
    }

    async handleCreateCart() {
        const callId = await createCallForDirectCart(this.state.prescriptionCode);
        this.props.callback(callId);
    }

    render() {
        return (
            <Modal show={this.props.open} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Gerar carrinho e consultar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                    <Col md='auto' sm='auto' xs='12'>
                            <div className='form-container'>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.prescriptionCode}
                                        placeholder='Codigo da Receita'
                                        onChange = {(e) => this.handleInput(e,'prescriptionCode')}
                                    />                   
                                </InputGroup>
                            </div>
                        </Col>
                        {/*
                            <Col md='auto' sm='auto' xs='12'>
                                <div className='form-container'>
                                    <InputGroup>
                                        <FormControl
                                            value={this.state.cep}
                                            placeholder='CEP'
                                            onChange = {(e) => this.handleInput(e,'cep')}
                                        />                   
                                    </InputGroup>
                                </div>
                            </Col>*/
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="primary" 
                                onClick={this.handleCreateCart}>
                            BUSCAR CARRINHO
                        </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PrescriptionGetCartModal;