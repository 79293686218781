import React from 'react';
import Funil from '../../components/FunilList';


class FunilList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className='funil-list-container'>
                {
                    this.props.lista.map((funil) => (
                        <Funil 
                            data = {funil}
                        />
                    ))
                }
            </div>
        );
    }
}

export default FunilList;