import React, { useEffect, useState } from 'react';
import { getProductsFromCall } from '../../services/pharmaceuticalCare';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ProductEcommerce from '../../components/ProductEcommerce';
import FreteButton from '../../components/FreteButton';
import Title from '../../components/Title';
import BuscarMedicamentosModal from '../../components/BuscarMedicamentosModal';
import FinalizarCompra from '../../components/FinalizarCompra';
import './styles.css';
export default function VendaFarmacia() {
  const [showPatientInfo, setShowPatientInfo] = useState(false);
  const [products, setProducts] = useState({});
  const [extraProducts, setExtraProducts] = useState([]);
  const [productsCart, setProductsCart] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [total, setTotal] = useState(0);
  const [totalFrete, setTotalFrete] = useState(-1);
  const [frete, setFrete] = useState({});
  const [cartAvailability, setCartAvailability] = useState(true);
  const [price, setPrice] = useState({});
  const [address, setAddress] = useState({});
  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (frete && frete.ShippingPrice !== undefined)
      setTotalFrete(frete.ShippingPrice);
  }, [frete]);

  useEffect(() => {
    let newTotal = 0;
    Object.keys(quantity).map((ean) => {
      newTotal = quantity[ean] * price[ean] + newTotal;
    });
    setTotal(newTotal);
  }, [setQuantity, setPrice, quantity, products, price]);

  useEffect(() => {
    calulateCartAvailability();
  }, [productsCart, quantity]);

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const getProducts = async () => {
    const products = await getProductsFromCall(id);
    setProducts(products);
  };

  const addProductToCart = (newQuantity, newPrice, product) => {
    setQuantity({ ...quantity, ...newQuantity });
    setPrice({ ...price, ...newPrice });
    let newProductsCart = productsCart || {};
    newProductsCart[product.EAN] = product;
    newProductsCart[product.EAN]['Quantity'] = newQuantity[product.EAN];
    setProductsCart(newProductsCart);
  };

  const calulateCartAvailability = () => {
    let productsUnavailable = 0;
    Object.keys(productsCart).forEach((key) => {
      if (productsCart[key].AvailableItens < quantity[productsCart[key].EAN]) {
        productsUnavailable++;
      }
    });
    if (productsUnavailable > 0) {
      setCartAvailability(false);
    } else setCartAvailability(true);
    setFrete({ ShippingPrice: -1 });
  };

  const addExtraProducts = (product) => {
    setExtraProducts([...extraProducts, { ...product, initialQuantity: 1 }]);
  };

  const formatExtraProducts = () => {
    const newExtraProducts = {
      EAN: '0000000000000',
      Quantity: 0,
      Price: 0.0,
      Label: 'Medicamentos Adicionados',
      Dosage: false,
      ProductOptions: extraProducts,
    };

    return newExtraProducts;
  };
  return (
    <Container>
      <iframe
        style={{ border: 0, width: '100%', height: '700px' }}
        src="https://docs.google.com/document/d/e/2PACX-1vSzKRzMa_gfSGs87It0187wMJUThcHgWH_23IKy1QMduvuUWliQPlzwnzkTnMUt78QAZWTBl540PoOo/pub?embedded=true"
      ></iframe>
    </Container>
  );
}
