import * as React from 'react';

function SvgLogoNexo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="193"
      height="40.547"
      viewBox="0 0 193 40.547"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_3"
            data-name="Retângulo 3"
            width="193"
            height="40.547"
            transform="translate(0 0)"
            fill="#3c0e6b"
          />
        </clipPath>
      </defs>
      <g id="Grupo_3" data-name="Grupo 3" transform="translate(9 2)">
        <g
          id="Grupo_1"
          data-name="Grupo 1"
          transform="translate(-9 -2)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Caminho_1"
            data-name="Caminho 1"
            d="M353.568,4.2c.9,3.85.542,7.636-2.418,14.216l-4.6,9.921L337.139,4.2H325.648l14.969,37.761h11.209l9.9-21.324c3.006-6.786,3.52-12.143,2.607-16.437Z"
            transform="translate(-217.744 -2.811)"
            fill="#3c0e6b"
          />
          <path
            id="Caminho_2"
            data-name="Caminho 2"
            d="M494.912,5.729a20.278,20.278,0,0,0-34.818,14.585,20.277,20.277,0,1,0,40.547,0,19.756,19.756,0,0,0-5.729-14.585m-7.881,21.817a8.606,8.606,0,0,1-6.623,3.007,8.48,8.48,0,0,1-6.582-3.007,10.309,10.309,0,0,1-2.763-7.232,10.749,10.749,0,0,1,2.682-7.354,8.808,8.808,0,0,1,13.326,0,10.75,10.75,0,0,1,2.681,7.354,10.4,10.4,0,0,1-2.722,7.232"
            transform="translate(-307.641 -0.001)"
            fill="#3c0e6b"
          />
          <path
            id="Caminho_3"
            data-name="Caminho 3"
            d="M57.323,22.055V17.07Q57.323,1.716,43,1.715A22.946,22.946,0,0,0,30.638,5.721q-3.579-4-10.888-4.006A21.969,21.969,0,0,0,8.992,4.761,12.565,12.565,0,0,1,2.865,6.329H0V40.3H10.82V13.611a15.45,15.45,0,0,1,7.021-2.19,5.827,5.827,0,0,1,3.978,1.392,4.862,4.862,0,0,1,1.591,3.859V40.3H34.071V17.07a27.664,27.664,0,0,0-.2-3.354,15.707,15.707,0,0,1,7.217-2.295,5.826,5.826,0,0,1,3.978,1.392,4.86,4.86,0,0,1,1.591,3.859V40.3H57.343l0-18.246Z"
            transform="translate(0 -1.147)"
            fill="#3c0e6b"
          />
          <path
            id="Caminho_4"
            data-name="Caminho 4"
            d="M226.382,27.389l-.3.251a16.366,16.366,0,0,1-15.869,2.53,10.36,10.36,0,0,1-5-4.4,22.909,22.909,0,0,0,14.327-.537,14.789,14.789,0,0,0,5.4-3.663,12.473,12.473,0,0,0,2.276-13.813A11.917,11.917,0,0,0,224.9,4.4,15.368,15.368,0,0,0,215.56.1c-6.125-.66-12.693,2.1-17.238,7.225a19.982,19.982,0,0,1-1.761-5.263l-.044-.237h-9.394l.043.327a28.939,28.939,0,0,0,7.069,15.381l-.019.081c-.772,9.288,4.419,18.027,12.625,21.25l.079.03a26.056,26.056,0,0,0,6.1,1.468,25.432,25.432,0,0,0,2.9.165,25.651,25.651,0,0,0,13.938-4.118l.2-.131ZM204.689,15.483a10.225,10.225,0,0,1,9.482-6.4c1.3.066,3.062.613,3.727,1.94a3.662,3.662,0,0,1-.39,3.788,4.922,4.922,0,0,1-1.715,1.416l-.091.047a11.715,11.715,0,0,1-7.54.621,12.219,12.219,0,0,1-3.474-1.409"
            transform="translate(-125.12 0)"
            fill="#3c0e6b"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoNexo;
