import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Modal,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { sendCallFeedback } from '../../services/pharmaceuticalCare';
import CepModal from '../CepModal';
export default function AssistenciaFarmaceuticaButtons({
  callId,
  enableNextCallButton,
}) {
  const [freezeButtons, setFreezeButtons] = useState(false);
  const [showNoInterestModal, setShowNoInterestModal] = useState(false);
  const [showNoContactModal, setShowNoContactModal] = useState(false);
  const [optIn, setOptIn] = useState(true);
  const [optOutReason, setOptOutReason] = useState('');

  const [showCepModal, setShowCepModal] = useState(false);

  const noContactFeedback = async () => {
    await sendCallFeedback(callId, 'no_contact');
    enableNextCallButton();
    setFreezeButtons(true);
    setShowNoContactModal(false);
  };

  const noInterestFeedback = async () => {
    await sendCallFeedback(
      callId,
      'not_interest',
      !optIn,
      !optIn ? optOutReason : ''
    );
    enableNextCallButton();
    setFreezeButtons(true);
    setShowNoInterestModal(false);
  };

  return (
    <Container>
      <ButtonGroup className="buttonFullWidth">
        <Button
          variant="warning"
          size="lg"
          disabled={freezeButtons}
          onClick={() => setShowNoContactModal(true)}
        >
          Sem contato
        </Button>
        <Button
          variant="danger"
          disabled={freezeButtons}
          onClick={() => setShowNoInterestModal(true)}
          size="lg"
        >
          Sem Interesse
        </Button>
        <Button
          variant="success"
          disabled={freezeButtons}
          onClick={() => setShowCepModal(true)}
          // href={'/venda-farmacia/' + callId}
          size="lg"
        >
          Comprar
        </Button>
      </ButtonGroup>
      <Modal
        show={showNoInterestModal}
        onHide={() => setShowNoInterestModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sem Interesse</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="radio"
                name="group1"
                label="Podemos contactar no futuro"
                value="optin"
                checked={optIn}
                onChange={(e) => setOptIn(e.target.checked)}
              />
              <Form.Check
                type="radio"
                name="group1"
                label="Não contactar no futuro"
                value="optout"
                checked={!optIn}
                onChange={(e) => setOptIn(!e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Motivo</Form.Label>
              <Form.Control
                type="text"
                disabled={optIn}
                placeholder="Escreva aqui o motivo"
                onChange={(e) => setOptOutReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Fechar</Button>
          <Button variant="primary" onClick={() => noInterestFeedback()}>
            Enviar Feedback
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNoContactModal}
        onHide={() => setShowNoContactModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sem Contato</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary">Fechar</Button>
          <Button variant="primary" onClick={() => noContactFeedback()}>
            Enviar Feedback Sem Contato
          </Button>
        </Modal.Footer>
      </Modal>
      <CepModal
        showCepModal={showCepModal}
        setShowCepModal={setShowCepModal}
        callId={callId}
      />
    </Container>
  );
}
