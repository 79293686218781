const orderShippingObject = {
  Address: 'Endereço',
  AddressCity: 'Cidade',
  AddressDistrict: 'Bairro',
  AddressNumber: 'Número',
  AddressState: 'Estado',
  AddressType: 'Tipo de Endereço',
  AddressComplement: 'Complemento',
  BuyerBirthDate: 'Data de Nascimento',
  BuyerCPF: 'CPF',
  BuyerName: 'Nome',
  BuyerRG: 'RG',
  ZipCode: 'CEP',
  BuyerEmail: 'Email',
  BuyerMobilePhone: 'Telefone Celular',
};

export default orderShippingObject;
