import React, { useEffect, useState } from 'react';
import {
  orderDetails as GetOrderDetails,
  getProductsFromCall,
  setOrderStatus,
} from '../../services/pharmaceuticalCare';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Badge,
  Form,
} from 'react-bootstrap';
import OrderStatuses from '../../helpers/orderStatuses';
import OrderStatusTransitions from '../../helpers/orderStatusTransition';
import ProductShow from '../../components/ProductShow';
import orderShippingObject from '../../helpers/orderShipping';
import Title from '../../components/Title';
import './styles.css';
export default function VendaFarmacia() {
  const [orderDetails, setOrderDetails] = useState({});
  const [orderShipping, setOrderShipping] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [products, setProducts] = useState({});

  const [productsCart, setProductsCart] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState({});
  const { id } = useParams();

  const [showJustification, setShowJustification] = useState('');

  const handleCloseJustification = () => {
    setShowJustification(false);
    clearNextStatus();
  };
  const handleShowJustification = () => setShowJustification(true);

  const [showExternalOrderReference, setExternalOrderReference] = useState('');

  const handleCloseExternalOrderReference = () => {
    setExternalOrderReference(false);
    clearNextStatus();
  };
  const handleShowExternalOrderReference = () =>
    setExternalOrderReference(true);

  const [nextStatus, setNextStatus] = useState('');
  const [nextStatusJustification, setNextStatusJustification] = useState('');
  const [nextExternalOrderReference, setNextExternalOrderReference] =
    useState('');

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    let newTotal = 0;
    Object.keys(quantity).map((ean) => {
      newTotal = quantity[ean] * price[ean] + newTotal;
    });
    setTotal(newTotal);
  }, [setQuantity, setPrice, quantity, products, price]);

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const getOrderDetails = async () => {
    const od = await GetOrderDetails(id);
    setOrderDetails(od);
    setOrderShipping(od.OrderShipping);
    setOrderItems(od.OrderItems);
  };

  const addProductToCart = (newQuantity, newPrice, product) => {
    setQuantity({ ...quantity, ...newQuantity });
    setPrice({ ...price, ...newPrice });
    let newProductsCart = productsCart || {};
    newProductsCart[product.EAN] = product;
    newProductsCart[product.EAN]['Quantity'] = newQuantity[product.EAN];
    setProductsCart(newProductsCart);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const checkStatusJustification = async (status) => {
    if (OrderStatuses[status]['reasonText']) {
      setNextStatus(status);
      handleShowJustification();
    } else if (OrderStatuses[status]['externalOrderReference']) {
      setNextStatus(status);
      handleShowExternalOrderReference();
    } else await setStatus(status, '');
  };

  const setStatus = async (status, reasonText, externalOrderReference) => {
    await setOrderStatus(id, status, reasonText, externalOrderReference);
    getOrderDetails();
    handleCloseJustification();
  };

  const clearNextStatus = () => {
    setNextStatus('');
    setNextStatusJustification('');
  };

  return (
    <>
      <Container>
        <Row>
          <Title title={`Pedido: #${id}`} />
          <pre className="rightAlign">
            Data do pedido: {orderDetails['OrderDate']}
          </pre>
        </Row>
        <Row>
          <Col>
            {Object.keys(orderShipping).map((o) => (
              <div>
                <b>{orderShippingObject[o]}: </b>
                {orderShipping[o]}
                <Badge
                  variant="secondary"
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => copyToClipboard(orderShipping[o])}
                >
                  copiar
                </Badge>
              </div>
            ))}
            {orderDetails['PDFUrl'] && (
              <div>
                <b>Receita PDF: </b>
                <a
                  href={orderDetails['PDFUrl']}
                  rel="noreferrer"
                  target="_blank"
                >
                  clique aqui para visualizar
                </a>
              </div>
            )}
            {orderDetails['UrlTracking'] && (
              <div>
                <b>Tracking link: </b>
                <a
                  href={orderDetails['UrlTracking']}
                  rel="noreferrer"
                  target="_blank"
                >
                  clique aqui para visualizar
                </a>
              </div>
            )}
          </Col>
          <Col>
            <Row>
              <Title title="Status atual" />
              <h4>
                <Badge
                  variant={
                    orderDetails['OperationStatus'] &&
                    OrderStatuses[orderDetails['OperationStatus']]['variant']
                  }
                >
                  {orderDetails['OperationStatus'] &&
                    OrderStatuses[orderDetails['OperationStatus']]['title']}
                </Badge>
              </h4>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Title title="Transitar para" />
              <div>
                {orderDetails['OperationStatus'] &&
                  OrderStatusTransitions[orderDetails['OperationStatus']].map(
                    (o) => (
                      <h5>
                        <Badge
                          style={{ cursor: 'pointer' }}
                          onClick={async () => {
                            await checkStatusJustification(o);
                          }}
                          variant={o && OrderStatuses[o]['variant']}
                        >
                          {o && OrderStatuses[o]['title']}
                        </Badge>
                      </h5>
                    )
                  )}
              </div>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <Title title="Produtos:" />
            {orderItems &&
              orderItems.length > 0 &&
              orderItems.map((product) => {
                return (
                  <ProductShow
                    product={product}
                    addProductToCart={addProductToCart}
                    editable={false}
                  />
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Title title={'Detalhes do Pagamento e Frete'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Tipo de Pagamento:</b>
          </Col>
          <Col className="rightAlign">
            <b>{orderDetails['PaymentType']}</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Tipo de Frete:</b>
          </Col>
          <Col className="rightAlign">
            <b>{orderDetails['ShippingType']}</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Valor do Frete:</b>
          </Col>
          <Col className="rightAlign">
            <b>{formatter.format(orderDetails['ShippingPrice'])}</b>
            <Badge
              variant="secondary"
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => copyToClipboard(orderDetails['ShippingPrice'])}
            >
              copiar
            </Badge>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <Title title={'Total'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Cupom de Desconto:</b>
          </Col>
          <Col className="rightAlign">
            <b>{orderDetails['DiscountCoupom']}</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Desconto Percentual:</b>
          </Col>
          <Col className="rightAlign">
            <b>{orderDetails['DiscountPercentage']}%</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Desconto Total:</b>
          </Col>
          <Col className="rightAlign">
            <b>{formatter.format(orderDetails['TotalDiscount'])}</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Preço Total:</b>
          </Col>
          <Col className="rightAlign">
            <b>{formatter.format(orderDetails['TotalValue'])}</b>
            <Badge
              variant="secondary"
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => copyToClipboard(orderDetails['TotalValue'])}
            >
              copiar
            </Badge>
          </Col>
        </Row>
      </Container>
      <Modal show={showJustification} onHide={handleCloseJustification}>
        <Modal.Header closeButton>
          <Modal.Title>Justificativa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Control
              onCha
              onChange={(e) => {
                setNextStatusJustification(e.target.value);
              }}
              placeholder="Justifique a mudança de status"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={nextStatusJustification === ''}
            onClick={() => {
              setStatus(nextStatus, nextStatusJustification);
              handleCloseJustification();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showExternalOrderReference}
        onHide={handleCloseExternalOrderReference}
      >
        <Modal.Header closeButton>
          <Modal.Title>Código de Comanda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              onChange={(e) => {
                setNextExternalOrderReference(e.target.value);
              }}
              placeholder="Digite o Código de Comanda"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              nextExternalOrderReference === '' ||
              String(nextExternalOrderReference).length !== 9
            }
            onClick={() => {
              setStatus(
                nextStatus,
                'externalOrderReference',
                nextExternalOrderReference
              );
              handleCloseExternalOrderReference();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
