import {myAxios} from './axios';

export const uploadDocumentoITI = async (prescriptionId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    const response = await myAxios.put(`/document/${prescriptionId}/validacaoiti`, formData, config);
    return response.data;
}
