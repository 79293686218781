import axios from 'axios';
import {myAxios} from './axios';
import { getAuthenticatedUser } from './userService';

const backend_token = process.env.REACT_APP_API_BASIC_TOKEN;
const backend_url = process.env.REACT_APP_API_URL;

export const getNextCall = async () => {
  const user = await getAuthenticatedUser();
  const nextCall = await axios.get(
    `${backend_url}/api/pharmaceuticalcare/nextcall?operatorLogin=${user.email}`,
    {
      headers: {
        Authorization: 'Basic ' + backend_token,
      },
    }
  );

  if (nextCall.data === 'null')
    await sendCallFeedback(nextCall.data.CallId, 'operator_opened');
  return nextCall.data;
};

export const sendCallFeedback = async (
  callId,
  status,
  isOptedOut = false,
  optOutReason
) => {
  if (
    ![
      'no_contact',
      'not_interest',
      'operator_opened',
      'proceed_to_buy',
      'order_completed',
      'order_canceled',
      'order_aborted',
    ].find((s) => s === status)
  )
    return false;

  const returnFeedback = await axios.put(
    `${backend_url}/api/pharmaceuticalcare/${callId}`,
    {
      OperationStatus: status,
      OptOut: isOptedOut,
      OptIOutReason: isOptedOut ? optOutReason : '',
    },
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );

  return returnFeedback.data;
};

export const getProductsFromCall = async (callId) => {
  const returnProducts = await myAxios.get(`/order/precart?callId=${callId}`);
  return returnProducts.data;
};

export const createCallForDirectCart = async (prescriptionCode) => {
  const returnProducts = await myAxios.post(`/order/call?validationCode=${prescriptionCode}`);
  return returnProducts.data;
};

export const searchProducts = async (searchTerm) => {
  if (!searchTerm) return [];
  const returnProducts = await axios.get(
    `${backend_url}/api/pharmaceuticalcare/search?q=${searchTerm}`,
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return returnProducts.data;
};

export const shippingEstimate = async (postalCode) => {
  if (!postalCode) return false;
  const estimate = await axios.get(
    `${backend_url}/api/pharmaceuticalcare/shipping?cep=${postalCode}`,
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return estimate.data;
};

export const postalCodeToAddress = async (postalCode) => {
  if (!postalCode) return false;
  try {
    const address = await axios.get(
      `https://brasilapi.com.br/api/cep/v1/${postalCode}`,
      {
        headers: {
          Authorization: `Basic ${backend_token}`,
        },
      }
    );
    return address.data;
  } catch (err) {
    return false;
  }
};

export const completeOrder = async (order, callId) => {
  if (!order) return false;
  const returnOrder = await axios.post(
    `${backend_url}/api/pharmaceuticalcare/order/createorder/${callId}`,
    order,
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return returnOrder.status;
};

export const orderDetails = async (orderId) => {
  if (!orderId) return false;
  const returnOrder = await axios.get(
    `${backend_url}/api/pharmaceuticalcare/order/${orderId}`,
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return returnOrder.data;
};

export const getOrders = async (filter = 'all') => {
  if (!filter) return false;
  const orders = await axios.get(
    `${backend_url}/api/pharmaceuticalcare/order/orders?orderStatus=${filter}`,
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return orders.data;
};

export const setOrderStatus = async (
  orderId,
  status,
  reasonText = '',
  externalOrderReference = ''
) => {
  const order = await axios.put(
    `${backend_url}/api/pharmaceuticalcare/order/${orderId}`,
    {
      OperationStatus: status,
      ReasonText: reasonText,
      ExternalOrderReference: externalOrderReference,
    },
    {
      headers: {
        Authorization: `Basic ${backend_token}`,
      },
    }
  );
  return order.data;
};
