import React from 'react';
import { getFunilList } from '../../services/funilService';
import FunilList from '../../components/FunilList';


class FunilContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lista: [],
            loading: false,
        }
        this.getFunilList = this.getFunilList.bind(this);
    }

    async getFunilList(){
        this.setState({loading: true});
        let resultados = [];
        try {
            resultados = await getFunilList();
            //resultados = list;
        }
        catch(err) {
            console.log("erro ao buscar usuários cadastrados.", err)
        }
        this.setState({lista: resultados, loading: false});
    }

    async componentDidMount(){
        await this.getFunilList();
    }   

    render() {
        return (
            <FunilList
                lista={this.state.lista}
                loading={this.state.loading}
                onFunilUpdate={this.getFunilList}
            />
        );
    }
}

export default FunilContainer;