import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Modal,
} from 'react-bootstrap';
import './styles.css';
export default function ScriptAssistance() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div class="rightAlign">
        <Button variant="primary" onClick={handleShow}>
          Script de Assistência
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Script de Assistência</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            style={{ border: 0, width: '100%', height: '500px' }}
            src="https://docs.google.com/document/d/e/2PACX-1vRvyb-Ry1-73cfmpBWftpVRQkX32V0pM-qw1Q4iM-IM_wAMmb6Vr9Yhu6NVs2vvTMr7aZ6JrrBINayJ/pub?embedded=true"
          ></iframe>
          {/* <p>
            Olá, XXXXX Sou A XXXXX, e sou Farmacêutica da Mevo, sua receita
            médica digital. Tudo bem? <br />
            Sabemos que é uma tecnologia nova e que duvidas em como utilizar,
            podem surgir. <br />
            Por isso, estou aqui para saber se precisa de alguma ajuda na compra
            do seu medicamento? <br />
            (ou se tem dúvidas sobre o processo)
          </p>
          <p>
            ( ) Sim.
            <br />
            Informe: "Muito obrigada. Apenas para você saber que por segurança
            será gravada." <br /> Siga com: "Você reparou que você recebeu um
            link por whats app (ou sms)?" <br /> Quais são as dúvidas? Como eu
            posso te ajudar?
          </p>
          <p>
            ( ) Não. Agradece a atenção e encerra
            <br />
            Plus: Se identificar que é possível acompanhar com o paciente o
            fluxo para que ele finalize a compra, podemos ajudar.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
