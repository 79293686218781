import React from 'react';
import { Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import PrescricoesLista from '../PrescricoesLista';
import Loading from '../Loading';
import './styles.css';

const defaultState = {
    buttonEnabled: false,
    codigo: '',
    email: '',
    celular: '',
    nome: '',
    idPaciente: '',
    textoBusca: ''
}

class BuscaPrescricao extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState
        this.handleInput = this.handleInput.bind(this);
        this.handleSearch =  this.handleSearch.bind(this);
        this.setTextoBusca = this.setTextoBusca.bind(this);
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value}, () => {
            const buttonEnabled = !!this.state.codigo || !!this.state.email || !!this.state.celular || !!this.state.nome || !!this.state.idPaciente;
            this.setState({buttonEnabled});
        });
    }

    handleSearch(){
        const obj = {
            codigo: this.state.codigo,
            email: this.state.email,
            celular: this.state.celular,
            nome: this.state.nome,
            id: !!this.state.idPaciente ? this.state.idPaciente : null
        }
        this.props.onPrescriptionUpdate(obj);
        this.setTextoBusca();
        this.setState({
            buttonEnabled: false,
            codigo: '',
            email: '',
            celular: '',
            nome: '',
            idPaciente: ''
        });
    }

    formatTextoBusca(){
        if(this.state.codigo){
            return `Codigo Validação ${this.state.codigo}`
        } 
        if(this.state.idPaciente){
            return `idPaciente ${this.state.idPaciente}`
        }
        if(this.state.celular){
            return `Celular ${this.state.celular}`
        }
        if(this.state.email){
            return `Email ${this.state.email}`
        }
        if(this.state.nome){
            return `Nome paciente ${this.state.nome}`
        }
    }

    setTextoBusca(){
        this.setState({textoBusca: this.formatTextoBusca()});
    }

    render() {
        return (
            <Container className="busca-prescricao-container" fluid>
                <Row style={{paddingBottom: '20px'}}>
                    <span>Busca de prescrições com base no paciente. Ao menos um campo é obrigatório:
                    </span>
                </Row>
                <Row className="justify-content-center">
                    <Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    value={this.state.codigo}
                                    placeholder='Código Validação'
                                    onChange = {(e) => this.handleInput(e,'codigo')}
                                />                   
                            </InputGroup>
                        </div>
                    </Col>
                    {/*<Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    value={this.state.email}
                                    placeholder='Email'
                                    onChange = {(e) => this.handleInput(e,'email')}
                                />   
                            </InputGroup>
                        </div>
        </Col>*/}
                    <Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    value={this.state.celular}
                                    placeholder='Celular'
                                    onChange = {(e) => this.handleInput(e,'celular')}
                                />   
                            </InputGroup>
                        </div>
                    </Col>
                    {/*<Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    value={this.state.nome}
                                    placeholder='Nome'
                                    onChange = {(e) => this.handleInput(e,'nome')}
                                />   
                            </InputGroup>
                        </div>
    </Col>*/}
                    <Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    value={this.state.idPaciente}
                                    placeholder='ID'
                                    onChange = {(e) => this.handleInput(e,'idPaciente')}
                                />   
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md='auto' sm='12' xs='auto'>
                            <Button
                                className="btn"
                                variant="info"
                                block
                                onClick={this.handleSearch}
                                disabled={!this.state.buttonEnabled}
                                label={'BUSCAR'}>
                                    BUSCAR
                            </Button>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                </Row>
                <Row>
                    <Col>
                        <div style={{paddingTop: '10px'}}>
                            <h4>Mostrando resultados para <strong>{this.state.textoBusca}</strong>:</h4>
                        </div>
                        {
                            this.props.loading ?
                            <Loading />
                                :
                            <PrescricoesLista
                                lista={this.props.lista}
                                onPrescriptionUpdate={this.handleSearch}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default BuscaPrescricao;