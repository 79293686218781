import React, { useEffect, useState } from 'react';
import './index.css';
import RoutesManager from './router';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthenticationDialog } from './components/AuthenticationDialog';
import { AuthService } from './services/AuthServices';
import { getAuthenticatedUser } from './services/userService';

export const Flex = (props) => (
  <div {...props} style={{ ...props.style, display: 'flex' }} />
);

export const Block = (props) => (
  <div {...props} style={{ ...props.style, display: 'block' }} />
);

function NavigationButton({ permission, ...props }) {
  const navStyle = {
    textDecoration: 'none',
    marginBottom: 10,
  };

  return (
    <a {...props} className="nav-button" style={navStyle}>
      <span className="nav-button-text">{props.children}</span>
    </a>
  );
}

const topNavList = [
  {
    children: 'HOME',
    href: '/',
  },
  {
    children: 'CONTA',
    href: '/conta',
  },
  {
    children: 'DISPENSAÇÃO',
    href: '/dispensacao',
    permission: 'DISPENSAÇÃO',
  },
  {
    children: 'ATENDIMENTO',
    href: '/atendimento',
    permission: 'ATENDIMENTO',
  },
  {
    children: 'PDV',
    href: '/pdv',
    permission: 'PDV',
  },
  {
    children: 'FILA DE COMUNICAÇÃO',
    href: '/atencao-farmaceutica',
    permission: 'ATENCAO-FARMACEUTICA',
  },
  {
    children: 'LISTA DE PEDIDOS',
    href: '/lista-pedidos',
    permission: 'LISTA-PEDIDOS',
  },
  {
    children: 'ESTOQUE',
    href: '/estoque',
    permission: 'ESTOQUE',
  },
  {
    children: 'FORMULARIO-ENTREGA',
    href: '/formulario-entrega',
    permission: 'FORMULARIO-ENTREGA',
  },
];

const bottomTabList = [
  {
    children: 'Logout',
    href: '/logout',
  },
];

function LeftNavigation({ userFeatures }) {
  return (
    <div className="nav-container">
      <div>
        {topNavList.map(
          (item) =>
            (!item.permission ||
              userFeatures?.some((perms) => perms === item?.permission)) && (
              <NavigationButton {...item} />
            )
        )}
      </div>
      <div>
        {bottomTabList.map(
          (item) =>
            (!item.permission ||
              userFeatures?.some((perms) => perms === item?.permission)) && (
              <NavigationButton {...item} />
            )
        )}
      </div>
    </div>
  );
}

const App = () => {
  const isSignedIn = AuthService.isSignIn();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [userFeatures, setUserFeatures] = useState([]);
  const [userName, setUserName] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (userFeatures?.length || !isSignedIn) return;
      const user = await getAuthenticatedUser();
      setUserName(user.nome);
      setUserFeatures(user.features);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Navbar {...{ isSignedIn, userFeatures, userName }} />
      <Flex>
        {isSignedIn && <LeftNavigation {...{ userFeatures }} />}
        <Block style={{ padding: 50, width: '100%' }}>
          {isSignedIn ? (
            <RoutesManager />
          ) : (
            <div
              style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <strong>Fazer Login</strong>
              <AuthenticationDialog
                isOpen={!isSignedIn && googleLoaded}
                onLoad={() => setGoogleLoaded(true)}
              />
            </div>
          )}
        </Block>
      </Flex>
    </div>
  );
};

export default App;
