import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Farmacia from './pages/Farmacia';
import Atendimento from './pages/Atendimento';
import Home from './pages/Home';
import Logout from './pages/Logout';
import Conta from './pages/Conta';
import PDV from './pages/PDV';
import Credencial from './pages/Credencial';
import AtencaoFarmaceutica from './pages/AtencaoFarmaceutica';
import Base from './pages/Base';
import DetalhesFarmacia from './pages/DetalhesFarmacia';
import VendaFarmacia from './pages/VendaFarmacia';
import VendaOrientacao from './pages/VendaOrientacao';
import ListaPedidos from './pages/ListaPedidos';
import Warehouse from './pages/Warehouse';
import DetalhePedido from './pages/DetalhePedido';
import Funil from './pages/Funil';
import FormularioEntrega from './pages/FormularioEntega';

export default function RoutesManager() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route path="/conta">
          <Conta />
        </Route>
        <Route exact path="/dispensacao/:id">
          <DetalhesFarmacia />
        </Route>
        <Route exact path="/dispensacao">
          <Farmacia />
        </Route>
        <Route path="/atendimento">
          <Atendimento />
        </Route>
        <Route path="/pdv">
          <PDV />
        </Route>
        <Route path="/credenciais">
          <Credencial />
        </Route>
        <Route path="/atencao-farmaceutica">
          <AtencaoFarmaceutica />
        </Route>
        <Route path="/Venda-farmacia/:id">
          <VendaFarmacia />
        </Route>
        <Route path="/Venda-orientacao/:id">
          <VendaOrientacao />
        </Route>
        <Route path="/detalhe-pedido/:id">
          <DetalhePedido />
        </Route>
        <Route path="/lista-pedidos">
          <ListaPedidos />
        </Route>
        <Route path="/estoque">
          <Warehouse />
        </Route>
        <Route path="/funil">
          <Funil />
        </Route>
        <Route path="/base">
          <Base />
        </Route>
        <Route path="/formulario-entrega">
          <FormularioEntrega />
        </Route>
      </Switch>
    </Router>
  );
}
