import React, { useState } from 'react';
import { Col, InputGroup, Button, Modal } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { shippingEstimate } from '../../services/pharmaceuticalCare';

export default function CepModal({ showCepModal, setShowCepModal, callId }) {
  const handleClose = () => setShowCepModal(false);
  const [shipmentAvailability, setShipmentAvailability] = useState('');
  const [loading, setLoading] = useState(false);
  const [postalCode, setPostalCode] = useState('');

  const shippingUrl = {
    SUCCESS: `/venda-farmacia/${callId}?cep=${postalCode}`,
    PARTIAL_SUCCESS: `/venda-orientacao/${callId}?cep=${postalCode}`,
  };

  const validateCep = (cep) => {
    return /^[0-9]{5}-[0-9]{3}$/.test(cep);
  };

  const shippingCalculate = async (postalCode) => {
    setLoading(true);
    setShipmentAvailability('INITIAL');

    const shipping = await shippingEstimate(postalCode);

    if (shipping.length === 0) {
      setShipmentAvailability('ERROR');
      setLoading(false);
      return false;
    } else {
      if (shipping.find((s) => s.EnablePurchase === true)) {
        setPostalCode(postalCode);
        setShipmentAvailability('SUCCESS');
        setLoading(false);
      } else {
        setPostalCode(postalCode);
        setShipmentAvailability('PARTIAL_SUCCESS');
        setLoading(false);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Modal show={showCepModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <InputGroup className="mb-3">
              <MaskedFormControl
                disabled={loading}
                placeholder="Digite o CEP"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={(e) => {
                  validateCep(e.target.value) &&
                    shippingCalculate(e.target.value);
                }}
                mask="11111-111"
              />
            </InputGroup>
          </Col>
          <Col>
            {shipmentAvailability === 'ERROR' && <p>Cep Não atendido</p>}
            {shipmentAvailability === 'SUCCESS' && <p>Cep Atendido</p>}
            {shipmentAvailability === 'PARTIAL_SUCCESS' && (
              <p>Não disponível para televendas</p>
            )}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {shipmentAvailability === 'SUCCESS' && (
            <Button
              variant="primary"
              disabled={shipmentAvailability !== 'SUCCESS'}
              onClick={handleClose}
              href={shippingUrl['SUCCESS']}
            >
              Prosseguir
            </Button>
          )}
          {shipmentAvailability === 'PARTIAL_SUCCESS' && (
            <Button
              variant="primary"
              disabled={shipmentAvailability !== 'PARTIAL_SUCCESS'}
              onClick={handleClose}
              href={shippingUrl['PARTIAL_SUCCESS']}
            >
              Prosseguir
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
