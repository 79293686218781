import React from 'react';
import { Button } from 'react-bootstrap';

export default function BotaoCarrinhoWhatsapp({ callback }) {
  return (
    <>
      <div class="leftAlign">
        <Button variant="success" onClick={callback}>
          Carrinho Whatsapp
        </Button>
      </div>
    </>
  );
}
