import React from 'react';
import { Button } from 'react-bootstrap';

export default function GetNextCallButton({ getNextCall }) {
  return (
    <>
      <div class="rightAlign">
        <Button variant="primary" onClick={getNextCall}>
          Próxima Ligação
        </Button>
      </div>
    </>
  );
}
