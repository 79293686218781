import React, { useEffect, useState } from 'react';
import { Container, Button, Form, Table, Badge } from 'react-bootstrap';
import { getOrders } from '../../services/pharmaceuticalCare';
import OrderStatuses from '../../helpers/orderStatuses';

export default function ListaPedidos() {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState('order_placed');
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    getOrdersPOS();
  }, [filter, refresh]);

  useEffect(() => {
    poollingForOrders();
  }, [refresh]);

  const poollingForOrders = async () => {
    const interval = 10000;
    setTimeout(() => setRefresh(Math.random()), interval);
  };
  const getOrdersPOS = async () => {
    const products = await getOrders(filter);
    setOrders(products);
  };

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const changeFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <>
      <Container>
        <Form.Control
          as="select"
          onChange={(s) => changeFilter(s.target.value)}
          aria-label="Filtro selecionado"
        >
          {Object.keys(OrderStatuses).map((o) => (
            <option value={o} selected={o === filter}>
              {OrderStatuses[o]['title']}
            </option>
          ))}
        </Form.Control>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Data da compra</th>
              <th>Status</th>
              <th>Valor Total</th>
              <th>Detalhes</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((o) => (
              <tr>
                <td>{o.OrderId}</td>
                <td>{o.BuyerName}</td>
                <td>{o.OrderDate}</td>
                <td>
                  {/* {o.OperationStatus} */}
                  <Badge variant={OrderStatuses[o.OperationStatus]['variant']}>
                    {OrderStatuses[o.OperationStatus]['title']}
                  </Badge>
                </td>
                <td>{formatter.format(o.TotalValue)}</td>
                <td className="rightAlign">
                  <Button href={`/detalhe-pedido/${o.OrderId}`}>
                    Detalhes
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
