import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TabSelector from '../../components/TabSelector';
import Title from '../../components/Title';
import './styles.css';
import EstoqueVirtualVTEXContainer from '../../containers/Warehouse/EstoqueVirtualVTEXContainer';


class Warehouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: ['ESTOQUE VIRTUAL VTEX', 'ESTOQUE VIRTUAL INTERNO'],
            selectedTab: 'ESTOQUE VIRTUAL VTEX'
        }
        this.handleTabClick = this.handleTabClick.bind(this);
    }
    async componentDidMount() {

    }

    handleTabClick(event){
        const text =  event.target.innerText;
        this.setState({selectedTab: text});
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'ESTOQUE'} />
                    </Col>
                </Row>
                <TabSelector
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    handleClick={this.handleTabClick}
                />
                <Row>
                    <Col>
                        {
                            this.state.selectedTab === 'ESTOQUE VIRTUAL VTEX' ?
                            <EstoqueVirtualVTEXContainer />
                            :
                            null
                        }
                        {
                            this.state.selectedTab === 'ESTOQUE VIRTUAL INTERNO' ?
                            <div>
                                Funcionalidade em desenvolvimento...
                            </div>
                            :
                            null
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Warehouse;