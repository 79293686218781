import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Badge } from 'react-bootstrap';
export default function ProductEcommerce({ product }) {
  const [eanQuantity, setEanQuantity] = useState({});
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Container>
      <Row>
        <Col>
          <b>{product.Label}</b>
        </Col>
      </Row>
      <Row>
        <Col>EAN</Col>
        <Col>Procfit ID</Col>
        <Col>Preço Unitário</Col>
        <Col>Preço Total</Col>
        <Col>Quantidade</Col>
      </Row>
      <Row>
        <Col>
          {product.EAN}
          <Badge
            variant="secondary"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            bg="primary"
            onClick={() => copyToClipboard(product.EAN)}
          >
            copiar
          </Badge>
        </Col>
        <Col>
          {product.ProcFitId}
          <Badge
            variant="secondary"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            bg="primary"
            onClick={() => copyToClipboard(product.ProcFitId)}
          >
            copiar
          </Badge>
        </Col>
        <Col>
          {formatter.format(product.UnitPrice)}
          <Badge
            variant="secondary"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            bg="primary"
            onClick={() => copyToClipboard(product.UnitPrice)}
          >
            copiar
          </Badge>
        </Col>
        <Col>
          {formatter.format(product.TotalPrice)}
          <Badge
            variant="secondary"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            bg="primary"
            onClick={() => copyToClipboard(product.TotalPrice)}
          >
            copiar
          </Badge>
        </Col>
        <Col>
          {product.Quantity}
          <Badge
            variant="secondary"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            bg="primary"
            onClick={() => copyToClipboard(product.Quantity)}
          >
            copiar
          </Badge>
        </Col>
      </Row>

      <hr />
    </Container>
  );
}
