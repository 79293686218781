import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Modal,
} from 'react-bootstrap';
import AssistenciaFarmaceuticaButtons from '../AssistenciaFarmaceuticaButtons';
import GetNextCallButton from '../GetNextCallButton';
import ScriptAssistance from '../ScriptAssitanceButton';
import './styles.css';
export default function LigacaoAtencaoFarmaceutica({ call, getNextCall }) {
  const [showNextCallButton, setShowNextCallButton] = useState(false);

  const enableNextCallButton = () => {
    setShowNextCallButton(true);
  };
  return (
    <>
      <Container>
        <Row>
          <Col xs={3}></Col>
          <Col xs={9} class="rightAlign">
            {!showNextCallButton && <ScriptAssistance />}
            {showNextCallButton && (
              <GetNextCallButton getNextCall={getNextCall} />
            )}
          </Col>
        </Row>
        <Row mb={2}>
          <Col xs={3}>
            <b>Identificação da Ligação</b>
          </Col>
          <Col>{call.CallId}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b>Nome do Paciente</b>
          </Col>
          <Col>{call.PatientName}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b>Número do Paciente</b>
          </Col>
          <Col>{call.PatientMobileNumber}</Col>
        </Row>
        <AssistenciaFarmaceuticaButtons
          enableNextCallButton={enableNextCallButton}
          callId={call.CallId}
        />
      </Container>
    </>
  );
}
