import {myAxios} from './axios';

export const unlockPrescrition = async (validationCode) => {
    const response = await myAxios.put(`/prescription/${validationCode}/unlock`);
    return response.data;
}

export const cancelPurchase = async (validationCode) => {
    const response = await myAxios.put(`/prescription/${validationCode}/cancelPurchase`);
    return response.data;
}

export const searchPrescriptions = async (searchObj) => {
    const data = searchObj;
    const response = await  myAxios.post('/prescription/search', data);
    return response.data;
}


export const searchPrescriptionForPDV = async (searchObj) => {
    const data = searchObj;
    const response = await  myAxios.post('/prescription/pdv', data);
    return response.data;
}