import React, { useEffect, useState } from 'react';
import { getProductsFromCall } from '../../services/pharmaceuticalCare';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ProductEcommerce from '../../components/ProductEcommerce';
import FreteButton from '../../components/FreteButton';
import Title from '../../components/Title';
import BuscarMedicamentosModal from '../../components/BuscarMedicamentosModal';
import FinalizarCompra from '../../components/FinalizarCompra';
import './styles.css';
export default function VendaFarmacia() {
  const [showPatientInfo, setShowPatientInfo] = useState(false);
  const [products, setProducts] = useState({});
  const [extraProducts, setExtraProducts] = useState([]);
  const [productsCart, setProductsCart] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [total, setTotal] = useState(0);
  const [totalFrete, setTotalFrete] = useState(-1);
  const [frete, setFrete] = useState({});
  const [cartAvailability, setCartAvailability] = useState(true);
  const [price, setPrice] = useState({});
  const [address, setAddress] = useState({});
  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (frete && frete.ShippingPrice !== undefined)
      setTotalFrete(frete.ShippingPrice);
  }, [frete]);

  useEffect(() => {
    let newTotal = 0;
    Object.keys(quantity).map((ean) => {
      newTotal = quantity[ean] * price[ean] + newTotal;
    });
    setTotal(newTotal);
  }, [setQuantity, setPrice, quantity, products, price]);

  useEffect(() => {
    calulateCartAvailability();
  }, [productsCart, quantity]);

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const getProducts = async () => {
    const products = await getProductsFromCall(id);
    setProducts(products);
  };

  const addProductToCart = (newQuantity, newPrice, product) => {
    setQuantity({ ...quantity, ...newQuantity });
    setPrice({ ...price, ...newPrice });
    let newProductsCart = productsCart || {};
    newProductsCart[product.EAN] = product;
    newProductsCart[product.EAN]['Quantity'] = newQuantity[product.EAN];
    setProductsCart(newProductsCart);
  };

  const calulateCartAvailability = () => {
    let productsUnavailable = 0;
    Object.keys(productsCart).forEach((key) => {
      if (productsCart[key].AvailableItens < quantity[productsCart[key].EAN]) {
        productsUnavailable++;
      }
    });
    if (productsUnavailable > 0) {
      setCartAvailability(false);
    } else setCartAvailability(true);
    setFrete({ ShippingPrice: -1 });
  };

  const addExtraProducts = (product) => {
    setExtraProducts([...extraProducts, { ...product, initialQuantity: 1 }]);
  };

  const formatExtraProducts = () => {
    const newExtraProducts = {
      EAN: '0000000000000',
      Quantity: 0,
      Price: 0.0,
      Label: 'Medicamentos Adicionados',
      Dosage: false,
      ProductOptions: extraProducts,
    };

    return newExtraProducts;
  };

  const choosedProducts = (productsCart) => {
    return Object.keys(productsCart)
      .filter((p) => productsCart[p]['Quantity'] > 0)
      .map((pr) => productsCart[pr]);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Title title={'Carrinho: ' + products.PatientName} />
          </Col>
        </Row>
        <Row>
          <Col className="rightAlign">
            <Button onClick={() => setShowPatientInfo(true)}>
              Informações do Paciente
            </Button>{' '}
            <BuscarMedicamentosModal addProduct={addExtraProducts} />
          </Col>
        </Row>
      </Container>
      <div>
        {products.Medicines &&
          products.Medicines.length > 0 &&
          products.Medicines.map((product) => {
            return (
              <ProductEcommerce
                product={product}
                addProductToCart={addProductToCart}
              />
            );
          })}
        {extraProducts.length > 0 && (
          <ProductEcommerce
            product={formatExtraProducts()}
            addProductToCart={addProductToCart}
          />
        )}
      </div>
      <Container>
        <Row>
          <Col>
            <Title title={'Total'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Preço Medicamentos:</b>
          </Col>
          <Col className="rightAlign">
            <b>{formatter.format(total)}</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Preço Frete:</b>
          </Col>
          <Col className="rightAlign">
            {(totalFrete > -1 && <b>{formatter.format(totalFrete)}</b>) || (
              <b>Não calculado</b>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Preço Total:</b>
          </Col>
          <Col className="rightAlign">
            <b>{formatter.format(total + (totalFrete < 0 ? 0 : totalFrete))}</b>
          </Col>
        </Row>
        <Row>
          <Col className="rightAlign">
            <FreteButton
              productAvailable={cartAvailability}
              setFinalAddress={setAddress}
              setFrete={setFrete}
            />

            {totalFrete > -1 && (
              <FinalizarCompra
                style={{ marginLeft: 10 }}
                generalInfo={products}
                productsCart={choosedProducts(productsCart)}
                address={address}
                frete={frete}
                totalPrice={total}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Modal
        show={showPatientInfo}
        onHide={() => setShowPatientInfo(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações do Paciente</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6}>
                <b>Código da Receita</b>
              </Col>
              <Col>{products.PrecriptionCode}</Col>
            </Row>
            <Row mb={2}>
              <Col xs={6}>
                <b>Identificação da Ligação</b>
              </Col>
              <Col>{products.CallId}</Col>
            </Row>
            <Row>
              <Col xs={6}>
                <b>Nome do Paciente</b>
              </Col>
              <Col>{products.PatientName}</Col>
            </Row>
            <Row>
              <Col xs={6}>
                <b>Número do Paciente</b>
              </Col>
              <Col>{products.PatientMobileNumber}</Col>
            </Row>
            <Row>
              <Col xs={6}>
                <b>Data da Receita</b>
              </Col>
              <Col>{products.ReceiptDate}</Col>
            </Row>
            <Row>
              <Col xs={6}>
                <b>Nome do Médico</b>
              </Col>
              <Col>{products.DoctorName}</Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
