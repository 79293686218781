import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Alert,
} from 'react-bootstrap';
export default function ProductEcommerce({ product, addProductToCart }) {
  const [eanQuantity, setEanQuantity] = useState({});
  const [eanPrice, setEanPrice] = useState({});
  const [eanList, setEanList] = useState([]);
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  useEffect(() => {
    //addQuantity(product.EAN,product.PRICE, product, product.initialQuantity)
  }, [eanList]);

  const addQuantity = (ean, price, product, quantity=1) => {
    console.log("chamadoooo", ean, price,product, quantity);
    const newEanQuantity = eanQuantity;
    const newEanPrice = eanPrice;
    newEanQuantity[ean] = newEanQuantity[ean] ? newEanQuantity[ean] + quantity : quantity;
    newEanPrice[ean] = price;
    setEanPrice(newEanPrice);
    setEanQuantity(newEanQuantity);
    addProductToCart(eanQuantity, eanPrice, product);
  };

  const removeQuantity = (ean, price, product) => {
    const newEanQuantity = eanQuantity;
    const newEanPrice = eanPrice;
    if (newEanQuantity[ean] === 0) return;
    newEanQuantity[ean] = newEanQuantity[ean] ? newEanQuantity[ean] - 1 : 0;
    newEanPrice[ean] = price;
    setEanPrice(newEanPrice);
    setEanQuantity(newEanQuantity);
    addProductToCart(eanQuantity, eanPrice, product);
  };

  return (
    <Container>
      <Row>
        <Col>
          <b>{product.Label}</b>
        </Col>
      </Row>
      {product.Dosage && (
        <Row>
          <Col>{product.Dosage}</Col>
        </Row>
      )}
      {product.ProductOptions.length === 0 && (
        <Alert variant="danger">Item Indisponível</Alert>
      )}
      {product.ProductOptions.length > 0 && (
        <Row>
          <Col>EAN</Col>
          <Col xs={5}>Nome</Col>
          <Col>Itens Disponíveis</Col>
          <Col>Preço</Col>
          <Col>Quantidade</Col>
        </Row>
      )}
      {product.ProductOptions.map((p) => {
        if (p.initialQuantity && eanQuantity[p.EAN] === undefined)
          addQuantity(p.EAN, p.Price, p, p.initialQuantity);
        return (
          <Row>
            <Col>{p.EAN}</Col>
            <Col xs={5}>{p.Label}</Col>
            <Col>{p.AvailableItens}</Col>
            <Col>{formatter.format(p.Price)}</Col>
            <Col>
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant="secondary"
                  onClick={() => removeQuantity(p.EAN, p.Price, p)}
                >
                  -
                </Button>
                <Button variant="secondary">
                  {eanQuantity[p.EAN] ? eanQuantity[p.EAN] : 0}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => addQuantity(p.EAN, p.Price, p)}
                >
                  +
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        );
      })}
      <hr />
    </Container>
  );
}
